// NPLModal.module.scss

@import '../../../styles/all.scss';

@keyframes modal-slide-up {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.c-NPLMobileModal,
.c-NPLDesktopModal {
  display: none;

  &--open {
    display: block;
  }

  &__backdrop {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }

  &__container {
    position: fixed;
    background-color: white;
    box-sizing: border-box;
    animation: modal-slide-up 0.3s ease-out;
    border-radius: 28px;
    box-shadow:
      0px 1px 4px 0px rgba(27, 27, 24, 0.1),
      0px 4px 6px 0px rgba(27, 27, 24, 0.04),
      0px 8px 16px 0px rgba(27, 27, 24, 0.04),
      0px 10px 20px 2px rgba(27, 27, 24, 0.02),
      0px 12px 24px 4px rgba(27, 27, 24, 0.04);

    &__no-gutter {
      padding: 0;
    }
  }

  &__inner {
    overflow-y: auto;
    height: 100%;
    padding: 24px 16px;

    @include for-desktop {
      padding: 32px;
    }

    &__no-gutter {
      padding: 0;
    }
  }

  &__eyebrow {
    align-self: flex-start;
    border-radius: 28px;
    width: 100%;
  }

  &__chin {
    align-self: flex-end;
    width: 100%;
    border-radius: 28px;
  }

  &__transparent_white_color {
    background: transparent;
    width: 90%;
  }

  &__close-modal {
    cursor: pointer;
    position: absolute;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    transition: all 0.2s ease-in-out;
    z-index: 2;
  }
}

.c-NPLMobileModal {
  &__container {
    bottom: 32px;
    left: 12px;
    right: 12px;
    margin: auto;
  }
}

.c-NPLDesktopModal {
  &__container {
    width: 80%;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: calc(100% - 5rem);
  }

  &__close-modal {
    &:hover {
      background: rgba(25, 28, 30, 0.08);
    }
  }
}

@media (max-width: 768px) {
  .c-NPLMobileModal {
    &__container {
      animation: modal-slide-up 0.3s ease-out;
      @keyframes modal-slide-up {
        from {
          bottom: -30%;
          opacity: 0;
        }
        to {
          bottom: 32px;
          opacity: 1;
        }
      }
    }
  }
}

@media (min-width: 769px) {
  .c-NPLDesktopModal {
    &__container {
      animation: modal-slide-up 0.3s ease-out;
      @keyframes modal-slide-up {
        from {
          top: calc(50% + 30px);
          opacity: 0;
        }
        to {
          top: 50%;
          opacity: 1;
        }
      }
    }
  }
}
