@import '../../../styles/all.scss';

.c-Button {
  border-radius: 4px;
  cursor: pointer;
  border: none;
  background-color: $bright-white;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 600;
  padding: 12px 24px;

  -webkit-tap-highlight-color: transparent;
  outline: none !important;

  &--fluid {
    width: 100%;
  }

  &--primary {
    background-color: $primary-color;
    color: black;
  }

  &--error {
    background-color: #e5484d;
    color: white;
  }
  &--animated {
    transition: background-color 0.3s ease-in-out;
    &--primary {
      &:hover {
        background-color: #d2a500;
      }
    }
    &--outline {
      &:hover {
        background-color: rgba(170, 170, 170, 0.2);
      }
    }
    &--plain {
      &:hover {
        text-decoration: underline;
        color: #666666;
      }
    }
  }

  &--outline {
    background-color: transparent;
    color: #222222;
    border: 1px solid #dbdbd7;
    box-sizing: border-box;
    border-radius: 4px;
  }

  &--with-border {
    border: 1.5px solid $dark-color;
  }

  &--enrollment-card {
    background-color: transparent;
    color: #f2f2f2;
    border: 1px solid #f2f2f2;

    @include for-desktop {
      border: 1.5px solid $dark-color;
      color: $dark-color;
    }
  }

  &--primary-inverted {
    background-color: transparent;
    border: 1.5px solid $primary-color;
    color: $primary-color;
  }

  &--secondary {
    background-color: $dark-color;
    color: #fff;
  }

  &--grey {
    background-color: $disabled-button-bg-color;
  }

  &--transparent {
    background-color: transparent;
    color: #fff;
    border: 1px solid #fff;
  }

  &--link {
    background-color: transparent;
    color: #fff;
    border: none;
    font-weight: 500;
  }

  &--secondary-bordered {
    background-color: $dark-color;
    color: #fff;
    border: 1px solid #fff;
  }

  &--bg-transparent-w-border {
    background-color: transparent;
    border: 1px solid $dark-color;
  }

  &--bg-transparent-w-border-light {
    background-color: transparent;
    border: 1px solid #fff;
    color: #fff;
  }

  &--bg-transparent-wo-border {
    background-color: transparent;
    color: #fff;
  }

  &--flat {
    background-color: #fff;
    color: black;
  }

  &--form-primary {
    background-color: $form-btn-primary-bg-color;
    color: $form-btn-primary-text-color;
  }

  &--form-secondary {
    background-color: $form-btn-secondary-bg-color;
    color: $form-btn-secondary-text-color;
    box-shadow: 0px 3px 28px rgba(0, 0, 0, 0.08);
  }

  &--shadow {
    box-shadow: $base-shadow;
  }

  &--narrow {
    padding: 12px;
  }

  &--slim {
    padding: 8px 12px;
  }

  &--disabled {
    cursor: not-allowed;
    background-color: $disabled-button-bg-color;
    color: $disabled-button-text-color;
  }

  &--small {
    border-radius: 4px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }

  &--new-version {
    border-radius: 4px;
    font-size: 18px;
    line-height: 27px;
    text-transform: uppercase;
  }

  &--rounded {
    border-radius: 25px;
  }

  &--transparent-with-black-text {
    color: #191c1e;
    background-color: transparent;
  }
  &--base {
    padding: 8px 12px;
    border-radius: 4px;
    border: 1px solid #666666;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: #66666614;
    }
  }
}
