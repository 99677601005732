.common-editor-styles {
  :global {
    color: #000;
    position: relative;
    line-height: 1.7;
    font-weight: 400;

    header {
      max-width: 580px;
      margin: auto;
      position: relative;
      display: flex;
      justify-content: center;
    }

    header a {
      max-width: 220px;
      margin: 20px 0 0 0;
      display: block;
    }

    header img {
      display: block;
      height: 100%;
      width: 100%;
    }

    header h1 {
      text-align: left;
      color: #333;
      display: inline-block;
      margin: 20px 0 0 0;
    }

    .editor-container {
      position: relative;
      display: block;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    .editor-container.tree-view {
      border-radius: 0;
    }

    .editor-container.plain-text {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    .editor-scroller {
      min-height: 150px;
      border: 0;
      display: flex;
      position: relative;
      outline: 0;
      z-index: 0;
      overflow: auto;
      resize: vertical;
    }

    .editor {
      flex: auto;
      position: relative;
      resize: vertical;
      z-index: -1;
    }

    .test-recorder-output {
      margin: 20px auto 20px auto;
      width: 100%;
    }

    pre {
      line-height: 1.1;
      background: #222;
      color: #fff;
      margin: 0;
      padding: 10px;
      font-size: 12px;
      overflow: auto;
      max-height: 400px;
    }

    .tree-view-output {
      display: block;
      background: #222;
      color: #fff;
      padding: 0;
      font-size: 12px;
      margin: 1px auto 10px auto;
      position: relative;
      overflow: hidden;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    pre::-webkit-scrollbar {
      background: transparent;
      width: 10px;
    }

    pre::-webkit-scrollbar-thumb {
      background: #999;
    }

    .link-editor .button.active,
    .toolbar .button.active {
      background-color: rgb(223, 232, 250);
    }

    .link-editor .link-input {
      display: block;
      width: calc(100% - 75px);
      box-sizing: border-box;
      margin: 12px 12px;
      padding: 8px 12px;
      border-radius: 15px;
      background-color: #eee;
      color: rgb(5, 5, 5);
      border: 0;
      outline: 0;
      position: relative;
      font-family: inherit;
    }

    .link-editor .font-size-wrapper,
    .link-editor .font-family-wrapper {
      display: flex;
      margin: 0 4px;
    }

    .link-editor select {
      padding: 6px;
      border: none;
      background-color: rgba(0, 0, 0, 0.075);
      border-radius: 4px;
    }

    .mention:focus {
      box-shadow: rgb(180 213 255) 0px 0px 0px 2px;
      outline: none;
    }

    .characters-limit {
      color: #888;
      font-size: 12px;
      text-align: right;
      display: block;
      position: absolute;
      left: 12px;
      bottom: 5px;
    }

    .characters-limit.characters-limit-exceeded {
      color: red;
    }

    .switches {
      z-index: 6;
      position: fixed;
      left: 10px;
      bottom: 70px;
      animation: slide-in 0.4s ease;
    }

    @keyframes slide-in {
      0% {
        opacity: 0;
        transform: translateX(-200px);
      }

      100% {
        opacity: 1;
        transform: translateX(0);
      }
    }

    .switch {
      display: block;
      color: #444;
      margin: 5px 0;
      background-color: rgba(238, 238, 238, 0.7);
      padding: 5px 10px;
      border-radius: 10px;
    }

    #rich-text-switch {
      right: 0;
    }

    #character-count-switch {
      right: 130px;
    }

    .switch label {
      margin-right: 5px;
      line-height: 24px;
      width: 100px;
      font-size: 14px;
      display: inline-block;
      vertical-align: middle;
    }

    .switch button {
      background-color: rgb(206, 208, 212);
      height: 24px;
      box-sizing: border-box;
      border-radius: 12px;
      width: 44px;
      display: inline-block;
      vertical-align: middle;
      position: relative;
      outline: none;
      cursor: pointer;
      transition: background-color 0.1s;
      border: 2px solid transparent;
    }

    .switch button:focus-visible {
      border-color: blue;
    }

    .switch button span {
      top: 0px;
      left: 0px;
      display: block;
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 12px;
      background-color: white;
      transition: transform 0.2s;
    }

    .switch button[aria-checked='true'] {
      background-color: rgb(24, 119, 242);
    }

    .switch button[aria-checked='true'] span {
      transform: translateX(20px);
    }

    p.editor-image {
      cursor: default;
      display: inline-block;
      position: relative;
      user-select: none;
      width: 100%;
    }

    .editor-image img {
      cursor: default;
      border-radius: 12px;
    }

    .editor-image img.focused {
      outline: 2px solid #946800;
      user-select: none;
    }

    .editor-image img.focused.draggable {
      cursor: grab;
    }

    .editor-image img.focused.draggable:active {
      cursor: grabbing;
    }

    .editor-shell
      .editor-image
      .image-caption-container
      .tree-view-output {
      margin: 0;
      border-radius: 0;
    }

    .editor-image .image-caption-container {
      display: block;
      position: absolute;
      bottom: 4px;
      left: 0;
      right: 0;
      padding: 0;
      margin: 0;
      border-top: 1px solid #fff;
      background-color: rgba(255, 255, 255, 0.9);
      min-width: 100px;
      color: #000;
      overflow: hidden;
    }

    .editor-image .image-caption-button {
      display: block;
      position: absolute;
      bottom: 20px;
      left: 0;
      right: 0;
      width: 30%;
      padding: 10px;
      margin: 0 auto;
      border: 1px solid rgba(255, 255, 255, 0.3);
      border-radius: 5px;
      background-color: rgba(0, 0, 0, 0.5);
      min-width: 100px;
      color: #fff;
      cursor: pointer;
      user-select: none;
    }

    .editor-image .image-caption-button:hover {
      background-color: rgba(60, 132, 244, 0.5);
    }

    .editor-image .image-edit-button {
      border: 1px solid rgba(0, 0, 0, 0.3);
      border-radius: 5px;
      background-image: url(/src/images/icons/pencil-fill.svg);
      background-size: 16px;
      background-position: center;
      background-repeat: no-repeat;
      width: 35px;
      height: 35px;
      vertical-align: -0.25em;
      position: absolute;
      right: 4px;
      top: 4px;
      cursor: pointer;
      user-select: none;
    }

    .editor-image .image-edit-button:hover {
      background-color: rgba(60, 132, 244, 0.1);
    }

    .editor-image .image-resizer {
      display: block;
      width: 7px;
      height: 7px;
      position: absolute;
      background-color: rgb(60, 132, 244);
      border: 1px solid #fff;
    }

    .editor-image .image-resizer.image-resizer-n {
      top: -6px;
      left: 48%;
      cursor: n-resize;
    }

    .editor-image .image-resizer.image-resizer-ne {
      top: -6px;
      right: -6px;
      cursor: ne-resize;
    }

    .editor-image .image-resizer.image-resizer-e {
      bottom: 48%;
      right: -6px;
      cursor: e-resize;
    }

    .editor-image .image-resizer.image-resizer-se {
      bottom: -2px;
      right: -6px;
      cursor: nwse-resize;
    }

    .editor-image .image-resizer.image-resizer-s {
      bottom: -2px;
      left: 48%;
      cursor: s-resize;
    }

    .editor-image .image-resizer.image-resizer-sw {
      bottom: -2px;
      left: -6px;
      cursor: sw-resize;
    }

    .editor-image .image-resizer.image-resizer-w {
      bottom: 48%;
      left: -6px;
      cursor: w-resize;
    }

    .editor-image .image-resizer.image-resizer-nw {
      top: -6px;
      left: -6px;
      cursor: nw-resize;
    }

    span.inline-editor-image {
      cursor: default;
      display: inline-block;
      position: relative;
      z-index: 1;
    }

    .inline-editor-image img {
      max-width: 100%;
      cursor: default;
    }

    .inline-editor-image img.focused {
      outline: 2px solid #946800;
    }

    .inline-editor-image img.focused.draggable {
      cursor: grab;
    }

    .inline-editor-image img.focused.draggable:active {
      cursor: grabbing;
    }

    .editor-shell
      .inline-editor-image
      .image-caption-container
      .tree-view-output {
      margin: 0;
      border-radius: 0;
    }

    .inline-editor-image.position-full {
      margin: 1em 0 1em 0;
    }

    .inline-editor-image.position-left {
      float: left;
      width: 50%;
      margin: 1em 1em 0 0;
    }

    .inline-editor-image.position-right {
      float: right;
      width: 50%;
      margin: 1em 0 0 1em;
    }

    .inline-editor-image .image-edit-button {
      display: block;
      position: absolute;
      top: 12px;
      right: 12px;
      padding: 6px 8px;
      margin: 0 auto;
      border: 1px solid rgba(255, 255, 255, 0.3);
      border-radius: 5px;
      background-color: rgba(0, 0, 0, 0.5);
      min-width: 60px;
      color: #fff;
      cursor: pointer;
      user-select: none;
    }

    .inline-editor-image .image-edit-button:hover {
      background-color: rgba(60, 132, 244, 0.5);
    }

    .inline-editor-image .image-caption-container {
      display: block;
      background-color: #f4f4f4;
      min-width: 100%;
      color: #000;
      overflow: hidden;
    }

    .emoji {
      color: transparent;
      caret-color: rgb(5, 5, 5);
      background-size: 16px 16px;
      background-position: center;
      background-repeat: no-repeat;
      vertical-align: middle;
      margin: 0 -1px;
    }

    .emoji-inner {
      padding: 0 0.15em;
    }

    .emoji-inner::selection {
      color: transparent;
      background-color: rgba(150, 150, 150, 0.4);
    }

    .emoji-inner::moz-selection {
      color: transparent;
      background-color: rgba(150, 150, 150, 0.4);
    }

    .emoji.happysmile {
      background-image: url(/editor-images/emoji/1F642.png);
    }

    .emoji.veryhappysmile {
      background-image: url(/editor-images/emoji/1F600.png);
    }

    .emoji.unhappysmile {
      background-image: url(/editor-images/emoji/1F641.png);
    }

    .emoji.heart {
      background-image: url(/editor-images/emoji/2764.png);
    }

    .keyword {
      color: rgb(241, 118, 94);
      font-weight: bold;
    }

    .actions {
      position: absolute;
      text-align: right;
      margin: 10px;
      bottom: 0;
      right: 0;
    }

    .actions.tree-view {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .actions i {
      background-size: contain;
      display: inline-block;
      height: 15px;
      width: 15px;
      vertical-align: -0.25em;
    }

    .table-cell-action-button-container {
      position: absolute;
      top: 0;
      left: 0;
      will-change: transform;
    }

    .table-cell-action-button {
      background-color: none;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0;
      position: relative;
      border-radius: 15px;
      color: #222;
      display: inline-block;
      cursor: pointer;
    }

    .action-button {
      background-color: #eee;
      border: 0;
      padding: 8px 12px;
      position: relative;
      margin-left: 5px;
      border-radius: 15px;
      color: #222;
      display: inline-block;
      cursor: pointer;
    }

    .action-button:hover {
      background-color: #ddd;
      color: #000;
    }

    .action-button-mic.active {
      animation: mic-pulsate-color 3s infinite;
    }

    button.action-button:disabled {
      opacity: 0.6;
      background: #eee;
      cursor: not-allowed;
    }

    @keyframes mic-pulsate-color {
      0% {
        background-color: #ffdcdc;
      }

      50% {
        background-color: #ff8585;
      }

      100% {
        background-color: #ffdcdc;
      }
    }

    .debug-timetravel-panel {
      overflow: hidden;
      padding: 0 0 10px 0;
      margin: auto;
      display: flex;
    }

    .debug-timetravel-panel-slider {
      padding: 0;
      flex: 8;
    }

    .debug-timetravel-panel-button {
      padding: 0;
      border: 0;
      background: none;
      flex: 1;
      color: #fff;
      font-size: 12px;
    }

    .debug-timetravel-panel-button:hover {
      text-decoration: underline;
    }

    .debug-timetravel-button {
      border: 0;
      padding: 0;
      font-size: 12px;
      top: 10px;
      right: 15px;
      position: absolute;
      background: none;
      color: #fff;
    }

    .debug-timetravel-button:hover {
      text-decoration: underline;
    }

    .debug-treetype-button {
      border: 0;
      padding: 0;
      font-size: 12px;
      top: 10px;
      right: 85px;
      position: absolute;
      background: none;
      color: #fff;
    }

    .debug-treetype-button:hover {
      text-decoration: underline;
    }

    .connecting {
      color: #999;
      overflow: hidden;
      position: absolute;
      text-overflow: ellipsis;
      top: 10px;
      left: 10px;
      user-select: none;
      white-space: nowrap;
      display: inline-block;
      pointer-events: none;
    }

    .ltr {
      text-align: left;
    }

    .rtl {
      text-align: right;
    }

    .toolbar {
      display: flex;
      margin-bottom: 1px;
      background: #fff;
      padding: 4px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      vertical-align: middle;
      flex-wrap: wrap;
      position: sticky;
      top: 0;
      @apply z-over-intercom;
    }

    button.toolbar-item {
      border: 0;
      display: flex;
      background: none;
      border-radius: 10px;
      padding: 8px;
      cursor: pointer;
      vertical-align: middle;
      flex-shrink: 0;
      align-items: center;
      justify-content: space-between;
    }

    button.toolbar-item:disabled {
      cursor: not-allowed;
    }

    button.toolbar-item.spaced {
      margin-right: 2px;
    }

    button.toolbar-item i.format {
      background-size: contain;
      display: inline-block;
      height: 18px;
      width: 18px;
      vertical-align: -0.25em;
      display: flex;
      opacity: 0.6;
    }

    button.toolbar-item:disabled .icon,
    button.toolbar-item:disabled .text,
    button.toolbar-item:disabled i.format,
    button.toolbar-item:disabled .chevron-down {
      opacity: 0.2;
    }

    button.toolbar-item.active {
      background-color: rgba(223, 232, 250, 0.3);
    }

    button.toolbar-item.active i {
      opacity: 1;
    }

    .toolbar-item:hover:not([disabled]) {
      background-color: #eee;
    }

    .toolbar-item.font-family .text {
      display: block;
      max-width: 40px;
    }

    .toolbar .code-language {
      width: 150px;
    }

    .toolbar .toolbar-item .text {
      display: flex;
      line-height: 20px;
      vertical-align: middle;
      font-size: 14px;
      color: #777;
      text-overflow: ellipsis;
      overflow: hidden;
      height: 20px;
      text-align: left;
      padding-right: 10px;
    }

    .toolbar .toolbar-item .icon {
      display: flex;
      width: 20px;
      height: 20px;
      user-select: none;
      margin-right: 8px;
      line-height: 16px;
      background-size: contain;
    }

    .toolbar i.chevron-down,
    .toolbar-item i.chevron-down {
      margin-top: 3px;
      width: 16px;
      height: 16px;
      display: flex;
      user-select: none;
    }

    .toolbar i.chevron-down.inside {
      width: 16px;
      height: 16px;
      display: flex;
      margin-left: -25px;
      margin-top: 11px;
      margin-right: 10px;
      pointer-events: none;
    }

    .toolbar .divider {
      width: 1px;
      background-color: #eee;
      margin: 0 4px;
    }

    .sticky-note-container {
      position: absolute;
      z-index: 9;
      width: 120px;
      display: inline-block;
    }

    .sticky-note {
      line-height: 1;
      text-align: left;
      width: 120px;
      margin: 25px;
      padding: 20px 10px;
      position: relative;
      border: 1px solid #e8e8e8;
      font-family: 'Reenie Beanie';
      font-size: 24px;
      border-bottom-right-radius: 60px 5px;
      display: block;
      cursor: move;
    }

    .sticky-note:after {
      content: '';
      position: absolute;
      z-index: -1;
      right: -0px;
      bottom: 20px;
      width: 120px;
      height: 25px;
      background: rgba(0, 0, 0, 0.2);
      box-shadow: 2px 15px 5px rgba(0, 0, 0, 0.4);
      transform: matrix(-1, -0.1, 0, 1, 0, 0);
    }

    .sticky-note.yellow {
      border-top: 1px solid #fdfd86;
      background: linear-gradient(
        135deg,
        #ffff88 81%,
        #ffff88 82%,
        #ffff88 82%,
        #ffffc6 100%
      );
    }

    .sticky-note.pink {
      border-top: 1px solid #e7d1e4;
      background: linear-gradient(
        135deg,
        #f7cbe8 81%,
        #f7cbe8 82%,
        #f7cbe8 82%,
        #e7bfe1 100%
      );
    }

    .sticky-note-container.dragging {
      transition: none !important;
    }

    .sticky-note div {
      cursor: text;
    }

    .sticky-note .delete {
      border: 0;
      background: none;
      position: absolute;
      top: 8px;
      right: 10px;
      font-size: 10px;
      cursor: pointer;
      opacity: 0.5;
    }

    .sticky-note .delete:hover {
      font-weight: bold;
      opacity: 1;
    }

    .sticky-note .color {
      border: 0;
      background: none;
      position: absolute;
      top: 8px;
      right: 25px;
      cursor: pointer;
      opacity: 0.5;
    }

    .sticky-note .color:hover {
      opacity: 1;
    }

    .sticky-note .color i {
      display: block;
      width: 12px;
      height: 12px;
      background-size: contain;
    }

    .excalidraw-button {
      border: 0;
      padding: 0;
      margin: 0;
      background-color: transparent;
    }

    .excalidraw-button.selected {
      outline: 2px solid rgb(60, 132, 244);
      user-select: none;
    }

    .github-corner:hover .octo-arm {
      animation: octocat-wave 560ms ease-in-out;
    }

    @keyframes octocat-wave {
      0%,
      100% {
        transform: rotate(0);
      }

      20%,
      60% {
        transform: rotate(-25deg);
      }

      40%,
      80% {
        transform: rotate(10deg);
      }
    }

    @media (max-width: 500px) {
      .github-corner:hover .octo-arm {
        animation: none;
      }

      .github-corner .octo-arm {
        animation: octocat-wave 560ms ease-in-out;
      }
    }

    .spacer {
      letter-spacing: -2px;
    }

    .editor-equation {
      cursor: default;
      user-select: none;
    }

    .editor-equation.focused {
      outline: 2px solid rgb(60, 132, 244);
    }

    button.item.dropdown-item-active {
      background-color: rgba(223, 232, 250, 0.3);
    }

    button.item.dropdown-item-active i {
      opacity: 1;
    }

    hr {
      padding: 2px 2px;
      border: none;
      margin: 0.75rem 0;
      cursor: pointer;
    }

    hr:after {
      content: '';
      display: block;
      height: 2px;
      background-color: #eeeeec;
      line-height: 2px;
    }

    hr.selected {
      outline: 2px solid rgb(60, 132, 244);
      user-select: none;
    }

    @keyframes CursorBlink {
      to {
        visibility: hidden;
      }
    }

    .dialog-dropdown {
      background-color: #eee !important;
      margin-bottom: 10px;
      width: 100%;
    }
  }
}

.typeahead-popover {
  background: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  width: 200px;

  :global {
    ul {
      padding: 0;
      list-style: none;
      margin: 0;
      border-radius: 8px;
      max-height: 200px;
      overflow-y: scroll;
    }

    ul::-webkit-scrollbar {
      display: none;
    }

    ul {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }

    ul li {
      margin: 0;
      min-width: 180px;
      font-size: 14px;
      outline: none;
      cursor: pointer;
      border-radius: 8px;
    }

    ul li.selected {
      background: #eee;
    }

    li {
      margin: 0 8px 0 8px;
      padding: 8px;
      color: #050505;
      cursor: pointer;
      line-height: 16px;
      display: flex;
      align-content: center;
      flex-direction: row;
      flex-shrink: 0;
      background-color: #fff;
      border-radius: 8px;
      border: 0;
    }

    li.active {
      display: flex;
      width: 20px;
      height: 20px;
      background-size: contain;
    }

    li:hover {
      background-color: #eee;
    }

    li .text {
      display: flex;
      line-height: 20px;
      flex-grow: 1;
      min-width: 150px;
    }

    li .icon {
      display: flex;
      width: 20px;
      height: 20px;
      user-select: none;
      margin-right: 8px;
      line-height: 16px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}
