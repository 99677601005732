.dropdown {
  display: block;
  position: fixed;
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2),
    0 2px 4px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  min-height: 40px;
  background-color: #fff;
  padding: 0.5rem;

  :global {
    .item {
      padding: 8px;
      color: #050505;
      cursor: pointer;
      width: 100%;
      line-height: 16px;
      display: flex;
      align-content: center;
      flex-direction: row;
      flex-shrink: 0;
      border-radius: 8px;
      border: 0;
      max-width: 250px;
      min-width: 100px;
    }

    .item.fontsize-item,
    .item.fontsize-item .text {
      min-width: unset;
    }

    .item .active {
      display: flex;
      width: 20px;
      height: 20px;
      background-size: contain;
    }

    .item .text {
      display: flex;
      line-height: 20px;
      flex-grow: 1;
      min-width: 150px;
    }

    .item .icon {
      display: flex;
      width: 20px;
      height: 20px;
      user-select: none;
      margin-right: 12px;
      line-height: 16px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }

    .divider {
      width: auto;
      background-color: #eee;
      margin: 4px 8px;
      height: 1px;
    }

    @media screen and (max-width: 1100px) {
      .dropdown-button-text {
        display: none !important;
      }

      .font-size .dropdown-button-text {
        display: flex !important;
      }

      .code-language .dropdown-button-text {
        display: flex !important;
      }
    }
  }
}
