.floating-text-format-popup {
  display: flex;
  background: #fff;
  padding: 4px;
  vertical-align: middle;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  opacity: 0;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  transition: opacity 0.5s;
  will-change: transform;

  :global {
    button.popup-item {
      border: 0;
      display: flex;
      background: none;
      border-radius: 10px;
      padding: 8px;
      cursor: pointer;
      vertical-align: middle;

      &.spaced {
        margin-right: 2px;
      }

      i.format {
        background-size: contain;
        height: 18px;
        width: 18px;
        margin-top: 2px;
        vertical-align: -0.25em;
        display: flex;
        opacity: 0.6;
      }

      &:disabled i.format {
        opacity: 0.2;
      }

      &.active {
        background-color: rgba(223, 232, 250, 0.3);

        i {
          opacity: 1;
        }
      }

      &:hover:not([disabled]) {
        background-color: #eee;
      }
    }

    button.popup-item:disabled {
      cursor: not-allowed;
    }

    select.popup-item {
      border: 0;
      display: flex;
      background: none;
      border-radius: 10px;
      padding: 8px;
      vertical-align: middle;
      appearance: none;
      width: 70px;
      font-size: 14px;
      color: #777;
      text-overflow: ellipsis;

      &.code-language {
        text-transform: capitalize;
        width: 130px;
      }
    }

    .popup-item .text {
      display: flex;
      line-height: 20px;
      vertical-align: middle;
      font-size: 14px;
      color: #777;
      text-overflow: ellipsis;
      width: 70px;
      overflow: hidden;
      height: 20px;
      text-align: left;
    }

    .popup-item .icon {
      display: flex;
      width: 20px;
      height: 20px;
      user-select: none;
      margin-right: 8px;
      line-height: 16px;
      background-size: contain;
    }

    i.chevron-down {
      margin-top: 3px;
      width: 16px;
      height: 16px;
      display: flex;
      user-select: none;

      &.inside {
        width: 16px;
        height: 16px;
        display: flex;
        margin-left: -25px;
        margin-top: 11px;
        margin-right: 10px;
        pointer-events: none;
      }
    }

    .divider {
      border-left: 1px solid #eee;
      margin: 0 4px;
    }

    @media (max-width: 1024px) {
      button.insert-comment {
        display: none;
      }
    }
  }
}
