.link-editor {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  max-width: 400px;
  width: 100%;
  opacity: 0;
  background-color: #fff;
  border-radius: 8px;
  transition: opacity 0.5s;
  will-change: transform;

  :global {
    .button {
      width: 20px;
      height: 20px;
      display: inline-block;
      padding: 6px;
      border-radius: 8px;
      cursor: pointer;
      margin: 0 2px;
    }

    .button.active {
      background-color: rgb(223, 232, 250);
    }

    .link-input {
      display: block;
      width: calc(100% - 75px);
      box-sizing: border-box;
      padding: 8px 12px;
      border-radius: 12px;
      color: rgb(5, 5, 5);
    }

    .link-view {
      display: block;
      border-radius: 15px;
      color: #946800;
      text-decoration: underline;
      border: 0;
      outline: 0;
      position: relative;
      font-family: inherit;
    }

    .link-view a {
      display: block;
      word-break: break-word;
      width: calc(100% - 33px);
    }

    .link-input a {
      color: rgb(33, 111, 219);
      text-decoration: underline;
      white-space: nowrap;
      overflow: hidden;
      margin-right: 30px;
      text-overflow: ellipsis;
    }

    .link-input a:hover {
      text-decoration: underline;
    }

    .font-size-wrapper,
    .font-family-wrapper {
      display: flex;
      margin: 0 4px;
    }

    select {
      padding: 6px;
      border: none;
      background-color: rgba(0, 0, 0, 0.075);
      border-radius: 4px;
    }

    .button.hovered {
      width: 20px;
      height: 20px;
      display: inline-block;
      background-color: #eee;
    }

    .button i,
    .actions i {
      background-size: contain;
      display: inline-block;
      height: 20px;
      width: 20px;
      vertical-align: -0.25em;
    }
  }
}
