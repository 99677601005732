.editor-theme {
  :global {
    .PlaygroundEditorTheme__ltr {
      text-align: left;
    }
    .PlaygroundEditorTheme__rtl {
      text-align: right;
    }
    .PlaygroundEditorTheme__paragraph {
      margin: 4px 0;
      position: relative;
    }
    .PlaygroundEditorTheme__quote {
      margin: 0;
      margin-left: 20px;
      margin-bottom: 10px;
      color: rgb(101, 103, 107);
      border-left-color: rgb(206, 208, 212);
      border-left-width: 4px;
      border-left-style: solid;
      padding-left: 16px;
    }
    .PlaygroundEditorTheme__h1 {
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      word-wrap: break-word;
      margin: 0;
    }
    .PlaygroundEditorTheme__h2 {
      font-size: 20px;
      font-weight: 700;
      line-height: 26px;
      word-wrap: break-word;
      margin: 0;
    }
    .PlaygroundEditorTheme__h3 {
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      word-wrap: break-word;
      margin: 0;
    }
    .PlaygroundEditorTheme__indent {
      --lexical-indent-base-value: 40px;
    }
    .PlaygroundEditorTheme__textBold {
      font-weight: bold;
    }
    .PlaygroundEditorTheme__textItalic {
      font-style: italic;
    }
    .PlaygroundEditorTheme__textUnderline {
      text-decoration: underline;
    }
    .PlaygroundEditorTheme__textStrikethrough {
      text-decoration: line-through;
    }
    .PlaygroundEditorTheme__textUnderlineStrikethrough {
      text-decoration: underline line-through;
    }
    .PlaygroundEditorTheme__textSubscript {
      font-size: 0.8em;
      vertical-align: sub !important;
    }
    .PlaygroundEditorTheme__textSuperscript {
      font-size: 0.8em;
      vertical-align: super;
    }
    .PlaygroundEditorTheme__textCode {
      background-color: rgb(240, 242, 245);
      padding: 1px 0.25rem;
      font-family: Menlo, Consolas, Monaco, monospace;
      font-size: 94%;
    }
    .PlaygroundEditorTheme__hashtag {
      background-color: rgba(88, 144, 255, 0.15);
      border-bottom: 1px solid rgba(88, 144, 255, 0.3);
    }

    .PlaygroundEditorTheme__link {
      color: #946800;
      text-decoration: underline;
      pointer-events: none;
    }

    .PlaygroundEditorTheme__link {
      color: #946800;
      text-decoration: underline;
      pointer-events: none;
    }

    .PlaygroundEditorTheme__link:hover {
      text-decoration: underline;
      cursor: pointer;
    }
    .PlaygroundEditorTheme__code {
      background-color: rgb(240, 242, 245);
      font-family: Menlo, Consolas, Monaco, monospace;
      display: block;
      padding: 8px 8px 8px 52px;
      line-height: 1.53;
      font-size: 13px;
      margin: 0;
      margin-top: 8px;
      margin-bottom: 8px;
      overflow-x: auto;
      position: relative;
      tab-size: 2;
    }
    .PlaygroundEditorTheme__code:before {
      content: attr(data-gutter);
      position: absolute;
      background-color: #eee;
      left: 0;
      top: 0;
      border-right: 1px solid #ccc;
      padding: 8px;
      color: #777;
      white-space: pre-wrap;
      text-align: right;
      min-width: 25px;
    }
    .PlaygroundEditorTheme__characterLimit {
      display: inline;
      background-color: #ffbbbb !important;
    }
    .PlaygroundEditorTheme__ol1 {
      padding: 0;
      margin: 0;
      list-style-position: inside;
      list-style-type: auto;
    }
    .PlaygroundEditorTheme__ol2 {
      padding: 0;
      margin: 0;
      list-style-type: upper-alpha;
      list-style-position: inside;
      list-style-type: auto;
    }
    .PlaygroundEditorTheme__ol3 {
      padding: 0;
      margin: 0;
      list-style-type: lower-alpha;
      list-style-position: inside;
      list-style-type: auto;
    }
    .PlaygroundEditorTheme__ol4 {
      padding: 0;
      margin: 0;
      list-style-type: upper-roman;
      list-style-position: inside;
      list-style-type: auto;
    }
    .PlaygroundEditorTheme__ol5 {
      padding: 0;
      margin: 0;
      list-style-type: lower-roman;
      list-style-position: inside;
      list-style-type: auto;
    }
    .PlaygroundEditorTheme__ul {
      padding: 0;
      margin: 0;
      list-style-position: inside;
      list-style-type: disc;
    }
    .PlaygroundEditorTheme__listItem {
      margin: 0 16px;
    }
    .PlaygroundEditorTheme__listItemChecked,
    .PlaygroundEditorTheme__listItemUnchecked {
      position: relative;
      margin-left: 8px;
      margin-right: 8px;
      padding-left: 24px;
      padding-right: 24px;
      list-style-type: none;
      outline: none;
    }
    .PlaygroundEditorTheme__listItemChecked {
      text-decoration: line-through;
    }
    .PlaygroundEditorTheme__listItemUnchecked:before,
    .PlaygroundEditorTheme__listItemChecked:before {
      content: '';
      width: 16px;
      height: 16px;
      top: 2px;
      left: 0;
      cursor: pointer;
      display: block;
      background-size: cover;
      position: absolute;
    }
    .PlaygroundEditorTheme__listItemUnchecked[dir='rtl']:before,
    .PlaygroundEditorTheme__listItemChecked[dir='rtl']:before {
      left: auto;
      right: 0;
    }
    .PlaygroundEditorTheme__listItemUnchecked:focus:before,
    .PlaygroundEditorTheme__listItemChecked:focus:before {
      box-shadow: 0 0 0 2px #a6cdfe;
      border-radius: 2px;
    }
    .PlaygroundEditorTheme__listItemUnchecked:before {
      border: 1px solid #999;
      border-radius: 2px;
    }
    .PlaygroundEditorTheme__listItemChecked:before {
      border: 1px solid rgb(61, 135, 245);
      border-radius: 2px;
      background-color: #3d87f5;
      background-repeat: no-repeat;
    }
    .PlaygroundEditorTheme__listItemChecked:after {
      content: '';
      cursor: pointer;
      border-color: #fff;
      border-style: solid;
      position: absolute;
      display: block;
      top: 6px;
      width: 3px;
      left: 7px;
      right: 7px;
      height: 6px;
      transform: rotate(45deg);
      border-width: 0 2px 2px 0;
    }
    .PlaygroundEditorTheme__nestedListItem {
      list-style-type: none;
    }
    .PlaygroundEditorTheme__nestedListItem:before,
    .PlaygroundEditorTheme__nestedListItem:after {
      display: none;
    }
    .PlaygroundEditorTheme__tokenComment {
      color: slategray;
    }
    .PlaygroundEditorTheme__tokenPunctuation {
      color: #999;
    }
    .PlaygroundEditorTheme__tokenProperty {
      color: #905;
    }
    .PlaygroundEditorTheme__tokenSelector {
      color: #690;
    }
    .PlaygroundEditorTheme__tokenOperator {
      color: #9a6e3a;
    }
    .PlaygroundEditorTheme__tokenAttr {
      color: #07a;
    }
    .PlaygroundEditorTheme__tokenVariable {
      color: #e90;
    }
    .PlaygroundEditorTheme__tokenFunction {
      color: #dd4a68;
    }
    .PlaygroundEditorTheme__mark {
      background: rgba(255, 212, 0, 0.14);
      border-bottom: 2px solid rgba(255, 212, 0, 0.3);
      padding-bottom: 2px;
    }
    .PlaygroundEditorTheme__markOverlap {
      background: rgba(255, 212, 0, 0.3);
      border-bottom: 2px solid rgba(255, 212, 0, 0.7);
    }
    .PlaygroundEditorTheme__mark.selected {
      background: rgba(255, 212, 0, 0.5);
      border-bottom: 2px solid rgba(255, 212, 0, 1);
    }
    .PlaygroundEditorTheme__markOverlap.selected {
      background: rgba(255, 212, 0, 0.7);
      border-bottom: 2px solid rgba(255, 212, 0, 0.7);
    }
    .PlaygroundEditorTheme__embedBlock {
      user-select: none;
    }
    .PlaygroundEditorTheme__embedBlockFocus {
      outline: 2px solid #946800;
      border-radius: 12px;
    }
    .PlaygroundEditorTheme__layoutContaner {
      display: grid;
      gap: 10px;
      margin: 10px 0;
    }
    .PlaygroundEditorTheme__layoutItem {
      border: 1px dashed #ddd;
      padding: 8px 16px;
    }
  }
}

.read-only {
  :global {
    .PlaygroundEditorTheme__link {
      pointer-events: auto;
    }
  }
}
