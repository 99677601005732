@import 'styles/all';

.gradient-background {
  background: linear-gradient(
    49deg,
    #3cca8c,
    #df7b43,
    #bf55df,
    #57a3e1,
    #dde83e,
    #37dfe8
  );
  background-size: 800% 800%;

  -webkit-animation: backgroundGrandietScroll 30s ease infinite;
  -moz-animation: backgroundGrandietScroll 30s ease infinite;
  animation: backgroundGrandietScroll 30s ease infinite;
}

@-webkit-keyframes backgroundGrandietScroll {
  0% {
    background-position: 0% 51%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 51%;
  }
}
@-moz-keyframes backgroundGrandietScroll {
  0% {
    background-position: 0% 51%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 51%;
  }
}
@keyframes backgroundGrandietScroll {
  0% {
    background-position: 0% 51%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 51%;
  }
}
